/* -------------- contact RWD --------------*/
// @media (max-width: $w_pc_b){ }

@media (max-width: $w_pc){ 
    .contact-form{
        .field{
            width: 50%;
        }
    }
}

@media (max-width: $w_pc_m){ 
    .contact-form{
        .field{
            width: 100%;
        }
    }
}

// @media (max-width: $w_tb_b){ }

// @media (max-width: $w_tb){ }

@media (max-width: $w_tb_m){ 
    .contact-form{
        .name-field{
            grid-template-columns: 1fr;
            gap: 8px;
        }
    }
}

@media (max-width: $w_mo){ 
    .contact-form{
        margin-top: 30px;
        .field{
            grid-template-columns: 1fr;
            gap: 10px;
        }
        .field-label{
            padding-top: 0;
        }
    }
}