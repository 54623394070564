/* -------------- layout RWD --------------*/
@media (max-width: $w_pc_b){ 
    .page-main-container{
        .container-grid{
            grid-template-columns: 240px auto;
            gap: 40px;
        }
    }
}

// @media (max-width: $w_pc){ }

@media (max-width: $w_pc_m){ 
    .page-main-container{
        .container-grid{
            display: block;
        }
        .aside-grid{
            margin-bottom: 40px;
        }
        .mobile-aside-toggle{
            display: block;
        }
        .aside-menu{
            display: none;
        }
        .main-link{
            font-size: 1rem /* 16/16 */;
            font-weight: normal;
            padding: 11px 25px;
        }
    }
}

// @media (max-width: $w_tb_b){ }

// @media (max-width: $w_tb){ }

@media (max-width: $w_tb_m){ 
    .breadcrumb-grid{
        padding: 15px 0;
    }
    .page-main-container{
        padding-top: 30px;
        .page-category-title{
            margin-bottom: 15px;
        }
    }
}

// @media (max-width: $w_mo){ }