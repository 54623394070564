/* -------------- pagination --------------*/
.page_box {
    @include flex($jfc: center);
    margin-top: 40px;

    .page_num {
        @include size(36px);
        @include flex($ai: center, $jfc: center);
        border-radius: 50%;
        background: $c_white;
        color: $c_font;
        font-size: 1rem
            /* 16/16 */
        ;
        margin-left: 5px;
        margin-right: 5px;

        &:hover {
            background: #eee;
        }

        &.active {
            background: $c_font;
            color: $c_white;
        }

        i {
            font-size: 1rem
                /* 16/16 */
            ;
        }
    }
    .jump-box{
        @include flex($ai:center);
        margin-left: 30px;
        .select-style{
            background: $c_grey;
            select{
                color: $c_font;
                font-size: 1rem /* 16/16 */;
                line-height: 1.1;
                min-width: 70px;
            }
        }
        span{
            color: $c_font;
            margin-left: 10px;
        }
    }
}