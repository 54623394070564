/* -------------- footer --------------*/
footer{
    background: $c_grey;
    padding: 40px 0;
    .container-grid{
        @include flex($jfc:space-between);
        color: $c_font;
        font-size: 1rem /* 16/16 */;
        font-weight: lighter;
        line-height: 1.4;
        position: relative;
    }
    .contact-info{
        a{
            color: $c_main;
            display: inline-block;
            &:hover{
                text-decoration: underline;
            }
        }
    }
    .web-name{
        font-size: 1.0625rem /* 17/16 */;
        font-weight: bold;
        text-transform: uppercase;
    }
    .info-grid{
        display: grid;
        gap: 15px 20px;
        grid-auto-flow: column;
        justify-content: flex-start;
        margin-top: 5px;
        span{
            display: inline-block;
            margin-right: 8px;
        }
        p a{
            text-decoration: none;
        }
    }
    .rights-info{
        text-align: right;
        .right{
            margin-bottom: 5px;
        }
        .designed, b{
            color: #9c9c9c;
            font-size: .8125rem /* 13/16 */;
        }
    }
    .top-btn{
        @include abs_pos($t:-60px, $r:50px);
        @include size(50px);
        border-radius: 50%;
        background: $c_main;
        transform: translate(100%, -100%);
        z-index: 1;
        &:hover{
            background: $c_active;
        }
        span{
            @extend %center;
            color: $c_white;
            font-size: .8125rem /* 13/16 */;
            line-height: 1;
            text-align: center;
            width: 100%;
        }
    }
}