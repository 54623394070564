/* -------------- resource --------------*/
.page-resource-img{
    margin-bottom: 30px;
    img{
        display: block;
        width: 100%;
    }
}

// .page-resource-head{
//     @include flex($ai:baseline, $jfc:space-between);
//     margin: 25px 0;
//     .page-filter-text{
//         color: $c_main;
//         font-size: 1.5625rem /* 25/16 */;
//         line-height: 1.2;
//     }
//     .filter-select{
//         border-bottom: 2px solid $c_font;
//         select{
//             color: $c_font;
//             font-size: 1rem /* 16/16 */;
//             padding: 7px 30px 7px 25px;
//         }
//     }
// }

