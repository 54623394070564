/* -------------- index RWD --------------*/
// @media (max-width: $w_pc_b){ }

@media (max-width: 1360px){ 
    .index-banner{
        .slider1, .slider4{
            width: 15%;
        }
        .slider2{
            left: 39%;
            width: 15%;
        }
        .slider3{
            left: 64%;
            width: 21%;
        }
        .text-grid{
            top: -42%;
            width: 70%;
            .text{
                transform: translateX(-60%) translateY(-120%);
            }
            .web-title{
                font-size: calc(70 * (100vw / 1920));
            }
        }
    }
    .index-news-grid .tabLinks li:not(:last-child){
        margin-right: 70px;
    }
}

@media (max-width: $w_pc){ 
    .index-banner{
        .text-grid{
            .text{
                transform: translateX(-60%) translateY(-70%);
            }
        }
    }
    .index-news-grid .news-grid-row{
        gap: 40px;
    }
    .index-contact-grid {
        .container-grid{
            padding-right: 40px;
        }
        .contact-info-list{
            padding: 70px 0;
            padding-right: 20px;
        }
    }
}

@media (max-width: $w_pc_m){ 
    .index-banner{
        .text-grid{
            top: -30%;
            left: 5%;
            width: 65%;
            .web-title{
                font-size: 2.5rem /* 40/16 */;
            }
        }
        .wide-limit{
            height: 500px;
        }
    }
    .index-news-grid{
        .tabLinks{
            li:not(:last-child){
                margin-right: 40px;
            }
            a{
                text-transform: inherit;
            }
        }
        .news-item{
            display: block;
            .date{
                margin-bottom: 5px;
            }
        }
    }
}

@media (max-width: $w_tb_b){ 
    .index-out-limit{
        overflow-x: hidden;
    }
    .index-banner{
        .text-grid{
            top: -10%;
            left: -10%;
            width: 80%;
            .web-title{
                font-size: 2.5rem /* 40/16 */;
            }
        }
        .wide-limit{
            height: 700px;
        }
        .slider4{
            width: 15%;
        }
        .slider1,.slider2{
            width: 20%;
        }
        .slider3{
            left: inherit;
            right: 7%;
            width: 35%;
        }
    }
    .index-link-grid{
        .link-fm .btn-fm{
            min-width: 200px;
        }
    }
}

@media (max-width: $w_tb){ 
    .index-link-grid{
        &:before{
            display: none;
        }
        .container-grid{
            grid-template-columns: 1fr;
        }
        .link-fm{
            padding: 70px 20px;
        }
        .left-link{
            background: url(../images/rp-blue.jpg) repeat;
            padding-top: 100px;
        }
    }
    .index-news-grid {
        padding-top: 70px;
        padding-bottom: 30px;
        .tabLinks{
            border: none;
            li{
                background: $c_white;
                padding: 10px;
                &:not(:last-child){
                    margin-right: 0;
                    margin-bottom: 5px;
                    margin-right: 5px;
                }
                &.active{
                    background: $c_linear;
                    &:after{
                        display: none;
                    }
                    a{
                        color: $c_white;
                    }
                }
            }
            a{
                text-transform: inherit;
            }
        }
        .tabContentGroup{
            margin-top: 10px;
        }
        .news-grid-row{
            display: block;
            .dec-img{
                display: none;
            }
        }
    }
    .index-contact-grid {
        .container-grid{
            display: block;
            padding-left: 0;
            padding-right: 0;
        }
        .contact-info-list{
            padding: 50px 20px;
        }
        .map-box{
            height: 300px;
        }
    }
}

@media (max-width: $w_tb_m){ 
    .index-banner{
        .text-grid{
            top: -15%;
            left: -15%;
            width: 110%;
            .web-title{
                font-size: 2.125rem /* 34/16 */;
            }
            .text{
                transform: translateX(-80%) translateY(-130%);
            }
        }
        .wide-limit{
            height: 500px;
        }
        .slider3{
            right: 0;
        }
    }
    .index-link-grid{
        .link-fm{
            padding: 50px 20px;
            .head{
                margin-bottom: 15px;
            }
        }
        .left-link{
            padding-top: 90px;
        }
    }
    .index-news-grid {
        padding-top: 50px;
        padding-bottom: 20px;
    }
}

@media (max-width: $w_mo){ 
    .index-banner{
        .text-grid{
            top: -5%;
            left: -5%;
            width: 110%;
            .web-title{
                font-size: 1.625rem /* 26/16 */;
            }
            .text{
                transform: translateX(-90%) translateY(-150%);
            }
            .sub-text{
                margin-bottom: 10px;
            }
        }
        .wide-limit{
            height: 400px;
        }
        .slider4{
            width: 20%;
        }
        .slider1,.slider2{
            width: 25%;
        }
        .slider3{
            width: 35%;
        }
    }
    .index-link-grid .link-fm .btn-fm{
        padding: 12px;
    }
}