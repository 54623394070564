/* -------------- index --------------*/
.index-banner{
    background: linear-gradient(180deg, $c_white, #f2f2f2);
    position: relative;
    z-index: 2;
    .banner-grid{
        @include maw(1540px);
        margin-left: auto;
        margin-right: auto;
        position: relative;
    }
    .wide-limit{
        height: calc(100svh - 127px);
    }
    .index-slider{
        cursor: pointer;
        position: absolute;
        height: auto;
        &:after{
            @include size(100%);
            @include abs_pos($t:0, $l:0);
            background: rgba($c_font, .3);
            content: '';
        }
    }
    .slider1, .slider4{
        bottom: 0;
        width: calc(280px / 1540px * 100%);
    }
    .slider1{
        left: 0;
    }
    .slider2{
        left: calc(560px / 1540px * 100%);
        bottom: 0;
        // transform: translateY(30%);
        margin-bottom: -7%;
        width: calc(230px / 1540px * 100%);
    }
    .slider3{
        left: calc(850px / 1540px * 100%);
        top: 0;
        width: calc(410px / 1540px * 100%);
        &:after{
            display: none;
        }
    }
    .slider4{
        right: 0;
    }
    .text-grid{
        @include abs_pos($t:-22%, $l:6%);
        width: 60%;
        .text{
            @extend %center;
            text-transform: uppercase;
        }
        .sub-text{
            color: #8d8e8f;
            font-size: .8125rem /* 13/16 */;
            margin-bottom: 20px;
        }
        .web-title{
            color: $c_font;
            font-size: calc(56 * (100vw / 1920));
            font-weight: 800;
            line-height: 1.1;
        }
    }
}

.index-link-grid{
    background: url(../images/rp-white.jpg) repeat;
    position: relative;
    z-index: 0;
    &:before{
        @include abs_pos($t:0, $l:0);
        @include size(calc(749 / 1920 * 100%), 100%);
        background: url(../images/rp-blue.jpg) repeat;
        content: '';
    }
    .container-grid{
        display: grid;
        grid-template-columns: calc(500 / 1440 * 100%) auto;
        padding: 0;
    }
    .link-fm{
        padding: 120px 40px 100px;
        position: relative;
        .head{
            margin-bottom: 25px;
            img{
                display: block;
                margin-left: auto;
                margin-right: auto;
            }
        }
        .btn-fm{
            border: 1px solid transparent;
            border-radius: 30px;
            display: block;
            font-size: .9375rem /* 15/16 */;
            line-height: 1.1;
            text-align: center;
            padding: 17px;
            margin: 5px;
            min-width: 230px;
            &.btn-normal{
                border-color: $c_main;
                color: $c_main;
                &:hover{
                    background: $c_hover;
                    border-color: $c_hover;
                }
            }
            &.btn-white{
                border-color: $c_white;
                color: $c_white;
                &:hover{
                    background: $c_white;
                    color: $c_main;
                }
            }
        }
        .link{
            @include flex($jfc:center);
        }
    }
}

.index-news-grid{
    background: $c_grey;
    padding-top: 90px;
    .tabLinks{
        @include flex;
        border-bottom: 5px solid $c_white;
        li{
            padding-bottom: 18px;
            &:not(:last-child){
                margin-right: 100px;
            }
            &.active{
                position: relative;
                &:after{
                    @include abs_pos($l:0, $b:-5px);
                    @include size(100%, 5px);
                    background: $c_linear;
                    border-radius: 3px;
                    content: '';
                }
            }
        }
        a{
            color: $c_main;
            font-size: 1.0625rem /* 17/16 */;
            line-height: 1.4;
            text-transform: uppercase;
            &:hover{
                color: $c_active;
            }
        }
    }
    .tabContentGroup{
        margin-top: 40px;
    }
    .news-grid-row{
        display: grid;
        grid-template-columns: 290px auto;
        gap: 70px;
    }
    .news-item{
        border-bottom: 1px solid $c_bd;
        display: grid;
        grid-template-columns: 85px auto;
        gap: 20px;
        padding: 15px 0;
        .date{
            color: $c_main;
            font-size: 1rem /* 16/16 */;
            line-height: 1.4;
        }
        .news-title{
            @include lineLimit(1);
            color: $c_font;
            font-size: 1rem /* 16/16 */;
            line-height: 1.4;
            &:hover{
                color: $c_active;
            }
        }
    }
    .btn-row{
        margin-top: 20px;
        padding-bottom: 20px;
        text-align: right;
    }
}

.index-contact-grid{
    background: linear-gradient(90deg, $c_white 50%, $c_hover 50%);
    .container-grid{
        display: grid;
        grid-template-columns: auto 410px;
        padding-right: 10%;
    }
    .contact-info-list{
        background: $c_white;
        padding: 100px 70px;
    }
    .contact-info{
        align-items: center;
        display: grid;
        grid-template-columns: 50px auto;
        gap: 25px;
        &:not(:last-child){
            margin-bottom: 25px;
        }
        .icon{
            @include flex($ai:center, $jfc:center);
            @include size(50px);
            border: 1px solid $c_bd;
            border-radius: 50%;
            img{
                display: block;
                max-width: 25px;
                max-height: 25px;
            }
        }
        .info{
            color: $c_font;
            font-size: 1rem /* 16/16 */;
            line-height: 1.2;
        }
        .info-title{
            font-weight: bold;
            margin-bottom: 3px;
        }
        .info-data{
            a{
                color: $c_font;
                &:hover{
                    color: $c_main;
                    text-decoration: underline;
                }
            }
        }
    }
    .map-box{
        iframe{
            @include size(100%);
        }
    }
}

