/* -------------- download RWD --------------*/
// @media (max-width: $w_pc_b){ }

// @media (max-width: $w_pc){ }

// @media (max-width: $w_pc_m){ }

// @media (max-width: $w_tb_b){ }

// @media (max-width: $w_tb){ }

@media (max-width: $w_tb_m){ 
    .page-download-list{
        .download-item{
            display: block;
        }
        .item-head{
            border-right: 0;
            border-bottom: 1px solid #e8e8e8;
            padding: 15px;
            .file-name{
                font-size: 1rem /* 16/16 */;
            }
        }
        .btn-download{
            background: #ededed;
            padding: 7px 15px;
        }
    }
}

// @media (max-width: $w_mo){ }