/* -------------- download --------------*/
.page-download-list{
    .download-item{
        align-items: center;
        background: #f8f8f8;
        display: grid;
        grid-template-columns: auto 115px;
        &:not(:last-child){
            margin-bottom: 15px;
        }
    }
    .item-head{
        align-items: center;
        border-right: 1px solid #e8e8e8;
        display: grid;
        grid-template-columns: auto 40px;
        gap: 10px;
        padding: 15px 20px;
        .file-name{
            color: $c_font;
            font-size: 1.0625rem /* 17/16 */;
            line-height: 1.3;
        }
        .file-type{
            text-transform: uppercase;
            font-weight: bold;
            text-align: right;
            &.pdf{
                color: #e20000;
            }
            &.doc{
                color: #4f7db2;
            }
            &.xml{
                color: #529f4c;
            }
        }
    }
    .btn-download{
        color: $c_font;
        display: block;
        font-size: 1.0625rem /* 17/16 */;
        line-height: 1.3;
        padding: 15px;
        &:hover{
            background: $c_active;
            color: $c_white;
        }
    }
}
