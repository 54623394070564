/* -------------- news RWD --------------*/
// @media (max-width: $w_pc_b){ }

// @media (max-width: $w_pc){ }

// @media (max-width: $w_pc_m){ }

@media (max-width: $w_tb_b){ 
    .page-news-head{
        .page-news-title{
            font-size: 1.125rem /* 18/16 */;
        }
    }
}

// @media (max-width: $w_tb){ }

@media (max-width: $w_tb_m){ 
    .page-news-list .news-item{
        padding-left: 0;
        padding-right: 0;
    }
    .page-news-img-list{
        grid-template-columns: repeat(2, 1fr);
        gap: 30px 20px;
    }
}

// @media (max-width: $w_mo){ }