/* -------------- contact --------------*/
.contact-form{
    @include flex;
    margin: 40px -20px 0;
    .field{
        display: grid;
        grid-template-columns: 130px auto;
        padding: 10px 20px;
        width: calc(100% / 3);
    }
    .half{
        width: 50%;
    }
    .wide{
        width: 100% !important;
    }
    .field-label{
        padding-top: 10px;
        label{
            color: $c_font;
            display: inline-block;
            font-size: 1rem /* 16/16 */;
            line-height: 1.2;
            &.must:after{
                color: #f24;
                content: '*';
                display: inline-block;
                padding-left: 3px;
            }
        }
    }
    .field-input{
        input[type="text"], input[type="tel"], select, textarea{
            color: $c_font;
            font-size: 1rem /* 16/16 */;
            line-height: 1.2;
            width: 100%;
        }
        input[type="text"], input[type="tel"], textarea{
            padding: 11px 10px;
        }
        input[type="text"], input[type="tel"], textarea, .select-style{
            background: #f2f3f4;
        }
        textarea{
            resize: none;
            height: 150px;
            margin-bottom: 10px;
        }
    }
    .name-field{
        align-items: center;
        display: grid;
        grid-template-columns: 60% auto;
        gap: 20px;
    }
    .checked-item{
        display: inline-block;
    }
    .btn-row{
        display: flex;
        justify-content: center;
        button{
            border: 1px solid $c_font;
            background: $c_font;
            color: $c_white;
            display: block;
            font-size: 1rem /* 16/16 */;
            text-align: center;
            padding: 10px;
            margin: 10px;
            width: 100px;
            &:hover{
                background: $c_white;
                color: $c_font;
            }
        }
    }
}

.page-contact-map{
    @include size(100%, 350px);
    iframe{
        @include size(100%);
    }
}