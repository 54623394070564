/* -------------- footer RWD --------------*/
@media (max-width: $w_pc_b){ 
    footer{
        .top-btn{
            right: 60px;
        }
    }
}

// @media (max-width: $w_pc){ }

@media (max-width: $w_pc_m){ 
    footer{
        .container{
            > *{
                width: 100%;
            }
        }
        .rights-info{
            border-top: 1px solid $c_bd;
            text-align: left;
            padding-top: 15px;
            margin-top: 15px;
            .right{
                text-align: left;
            }
        }
        .top-btn{
            @include size(45px);
            transform: translate(100%, 0);
        }
    }
}

// @media (max-width: $w_tb_b){ }

@media (max-width: $w_tb){ 
    footer{
        padding: 30px 0;
    }
}

@media (max-width: $w_tb_m){ 
    footer{
        padding: 25px 0;
        .container{
            font-weight: normal;
        }
        .info-grid{
            grid-auto-flow: row;
            gap: 0;
            span{
                font-weight: bold;
            }
        }
        .rights-info{
            .right{
                font-size: .8125rem /* 13/16 */;
            }
        }
        .top-btn{
            top: -50px;
        }
    }
}

// @media (max-width: $w_mo){ }