/* -------------- header RWD --------------*/
// @media (max-width: $w_pc_b){ }

@media (max-width: $w_pc){ 
    .header-grid{
        .main-menu{
            gap: 40px;
        }
    }
}

@media (max-width: $w_pc_m){ 
    .header-grid{
        padding: 15px 0;
        .top-row{
            gap: 20px;
        }
        .mobile-toggle{
            display: block;
        }
        nav{
            @include size(300px, 100dvh);
            background: $c_white;
            box-shadow: 2px 0 3px rgba($c_font, .1);
            display: none;
            position: fixed;
            top: 0;
            left: 0;
            margin-top: 0;
            padding: 25px;
            overflow: auto;
        }
        .main-menu{
            display: block;
            >li{
                &:not(:last-child){
                    border-bottom: 1px dashed $c_bd;
                }
            }
            .main-link{
                padding: 15px 0;
            }
            .sub-menu{
                position: static;
                padding-top: 0;
                transform: translate(0, 0);
                width: 100%;
            }
        }
    }
}

// @media (max-width: $w_tb_b){ }

@media (max-width: $w_tb){ 
    .header-grid{
        .logo{
            width: 75px;
        }
    }
}

@media (max-width: $w_tb_m){ 
    .header-grid{
        .logo{
            width: 60px;
        }
        .top-row{
            gap: 10px;
        }
        .keyword-form{
            width: 140px;
        }
        .lang{
            gap: 5px;
            a{
                @include size(30px);
            }
        }
    }
}

// @media (max-width: $w_mo){ }