$c_font: #222;
$c_white: #fff;
$c_main: #1e4d84;
$c_dark: #334a8d;
$c_active: #83b5d8;
$c_hover: #c3d4e0;
$c_grey: #f2f3f4;
$c_bd: #dfdfdf;
$c_linear: linear-gradient( 90deg, rgb(49,128,184) 0%, rgb(40,154,176) 75%, rgb(28,161,166) 100%);

$w_pc_b: calc(1360px + 80px);
$w_pc: 1280px;
$w_pc_m: 1024px;
$w_tb_b: 960px;
$w_tb: 768px;
$w_tb_m: 640px;
$w_mo: 480px;
$w_i6p: 414px;
$w_i6: 375px;
$w_mo_m: 320px;

$fz: 10px;

$fa-font-path: "../webfonts";