/* -------------- layout --------------*/
* {
	box-sizing: border-box;
	outline: none;
	word-break: break-word;
}

html, body{
	@include maw(1920px);
	margin-left: auto;
	margin-right: auto;
}

body{
	font-family: 'Open Sans', Arial, 'LiHei Pro', 'fix Microsoft JhengHei', 'Microsoft JhengHei','新細明體', sans-serif;
}

.hide{
	display: none !important;
}

.clearfix{
	@extend %clearfix;
}
a{
	text-decoration: none;
	transition: all .4s ease;
}

.is-wrapper *{
    font-family: 'Open Sans', Arial, 'LiHei Pro', 'fix Microsoft JhengHei', 'Microsoft JhengHei','新細明體', sans-serif, FontAwesome !important;
    letter-spacing: 0 px!important;
}
.clearfloat{
	clear: both;
}

.unreset{
	line-height: initial;
	a{
		text-decoration: underline;
	}
	img{
		max-width: 100% !important;
		height: auto !important; 
	}
	.oembed-provider-youtube {
		position: relative;
		padding-bottom: 56.25%;
		height: 0;
		overflow: hidden;
		iframe, object, embed{
			@include size(100%);
			@include abs_pos($t:0, $l:0);
		}
	}
}

.img-limit{
	position: relative;
	&.index-slider-img{
		@include fiximg(100%, calc(700 / 410 * 100%));
	}
	&.news-img{
		@include fiximg(100%, calc(182 / 320 * 100%));
	}
}

.btn-more{
	align-items: flex-start;
	display: inline-flex;
	&:hover{
		span{
			font-weight: bold;
		}
	}
	.icon{
		width: 22px;
		svg{
			fill: $c_main;
		}
	}
	span{
		color: $c_main;
		display: inline-block;
		font-size: 1rem /* 16/16 */;
		line-height: 1.2;
		padding-left: 5px;
	}
}

.breadcrumb-grid{
	background: url(../images/rp-blue.jpg);
	padding: 25px 0;
	.breadcrumb{
		@include flex($ai:center);
		li{
			&:not(:last-child){
				margin-right: 25px;
				position: relative;
				&:after{
					@include abs_pos($t:2px, $r:-15px);
					content: '>';
					color: $c_white;
					font-size: .875rem /* 14/16 */;
					line-height: 1.2;
				}
			}
		}
		a{
			color: #a1c8e3;
			font-size: .875rem /* 14/16 */;
			line-height: 1.2;
			&:hover{
				text-decoration: underline;
			}
		}
	}
}

.page-main-container{
	background: url(../images/bg-page.png) no-repeat;
	background-position: top center;
	padding-top: 40px;
	.container-grid{
		display: grid;
		grid-template-columns: 300px auto;
		gap: 80px;
		&.wide{
			display: block;
			.page-title{
				color: $c_font;
				font-weight: normal;
				text-transform: uppercase;
			}
		}
	}
	.page-category-title{
		color: $c_font;
		font-size: 1.5625rem /* 25/16 */;
		letter-spacing: 1px;
		margin-bottom: 20px;
		text-transform: uppercase;
	}
	aside{
		height: calc(100% - 45px);
	}
	.mobile-aside-toggle{
		background: $c_linear;
		color: $c_white;
		font-size: 1rem /* 16/16 */;
		line-height: 1;
		padding: 12px 10px;
		width: 100%;
		display: none;
		i{
			padding-right: 8px;
		}
	}
	.aside-menu{
		background: url(../images/rp-grey.jpg);
		border-top: 1px dotted #888;
		height: 100%;
		> li{
			border-bottom: 1px dotted #888;
			&.active{
				.main-link{
					background: #f8f8f8;
					position: relative;
					&:before{
						@include abs_pos($t:0, $l:0);
						@include size(5px, 100%);
						background: $c_main;
						content: '';
					}
				}
			}
			&.open{
				.main-link{
					&.has-sub{
						&:after{
							content: '\f068';
						}
					}
				}
				.sub-menu{
					display: block;
				}
			}
		}
	}
	.main-link{
		background: $c_white;
		color: $c_font;
		display: block;
		font-size: 1.0625rem /* 17/16 */;
		font-weight: bold;
		line-height: 1.2;
		padding: 13px 25px;
		&.has-sub{
			position: relative;
			&:after{
				@include abs_pos($t:14px, $r:10px);
				content: '\2b';
				color: #c0c0c0;
				font-family: 'Font Awesome 6 Free';
				font-weight: bold;
				font-size: .9375rem /* 15/16 */;
			}
		}
		&:hover{
			color: $c_active;
		}
	}
	.page-title{
		border-bottom: 1px solid $c_font;
		color: $c_main;
		font-size: 1.5625rem /* 25/16 */;
		font-weight: bold;
		padding-bottom: 20px;
		margin-bottom: 30px;
		position: relative;
		span{
			@include abs_pos($b:-4px, $l:0);
			@include size(70px, 8px);
			border: 1px solid $c_font;
			border-radius: 15px;
			background: $c_white;
			display: block;
			overflow: hidden;
			&:before{
				@include abs_pos($t:0, $l:0);
				@include size(25px, 8px);
				background: $c_active;
				transform: skewX(45deg);
				content: '';
			}
		}
	}
	.page-main{
		padding-bottom: 60px;
	}
	.sub-menu{
		border-top: 1px dotted #888;
		background: $c_white;
		display: none;
		padding-left: 20px;
		> li{
			&:not(:last-child){
				border-bottom: 1px dotted #888;
			}
			&.active{
				.sub-link{
					position: relative;
					&:before{
						@include abs_pos($t:0, $l:0);
						@include size(5px, 100%);
						background: $c_hover;
						content: '';
					}
				}
			}
		}
	}
	.sub-link{
		color: $c_font;
		display: block;
		font-size: 1rem /* 16/16 */;
		line-height: 1.2;
		padding: 13px 25px;
		&:hover{
			color: $c_hover;
		}
	}
}