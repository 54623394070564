/* -------------- pagination RWD --------------*/
// @media (max-width: $w_pc_b){ }

// @media (max-width: $w_pc){ }

// @media (max-width: $w_pc_m){ }

// @media (max-width: $w_tb_b){ }

// @media (max-width: $w_tb){ }

@media (max-width: $w_tb_m) {
    .page_box{
        .page_num {
            margin-left: 3px;
            margin-right: 3px;
        }
        .jump-box{
            justify-content: center;
            margin-left: 0;
            margin-top: 20px;
            width: 100%;
        }
    }
}

// @media (max-width: $w_mo){ }