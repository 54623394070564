/* -------------- news --------------*/
.page-news-list {
    .news-item {
        border-bottom: 1px solid #e0e0e0;
        display: grid;
        grid-template-columns: 100px auto;
        padding: 13px 20px;

        &:hover {
            background: #e9f2f4;
        }

        .date {
            color: $c_main;
            font-size: 1rem
                /* 16/16 */
            ;
            line-height: 1.3;
        }

        .news-title {
            a {
                color: $c_font;
                font-size: 1rem
                    /* 16/16 */
                ;
                line-height: 1.3;

                &:hover {
                    font-weight: bold;
                }
            }
        }
    }
}

.page-news-img-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;

    .news-img-item {
        .img-box {
            display: block;
        }

        .content {
            margin-top: 15px;

            .date {
                color: $c_main;
                font-size: 1rem
                    /* 16/16 */
                ;
                line-height: 1.3;
            }

            .news-title {
                margin-top: 5px;

                a {
                    color: $c_font;
                    font-size: 1rem
                        /* 16/16 */
                    ;
                    line-height: 1.3;

                    &:hover {
                        color: $c_active;
                    }
                }
            }
        }
    }
}

.page-news-head {
    .page-news-title {
        color: $c_font;
        font-size: 1.25rem
            /* 20/16 */
        ;
        line-height: 1.4;
        margin-bottom: 20px;
    }

    .news-head-info {
        background: #e9f2f4;
        display: grid;
        grid-template-columns: 100px auto;
        padding: 10px 20px;
        margin-bottom: 20px;

        .date {
            color: $c_main;
            font-size: 1rem
                /* 16/16 */
            ;
            line-height: 1.3;
        }

        .sns-grid {
            a {
                color: $c_active;
                font-size: 1.125rem
                    /* 18/16 */
                ;
                line-height: 1.2;

                &:not(:last-child) {
                    margin-right: 10px;
                }

                &:hover {
                    color: $c_main;
                }
            }
        }
    }
}