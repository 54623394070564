/* -------------- header --------------*/
.header-grid{
    padding: 25px 0;
    position: relative;
    z-index: 3;
    .container-grid{
        @include flex($ai:center, $jfc:space-between);
        position: relative;
    }
    .logo{
        display: block;
        width: 90px;
        img{
            display: block;
            width: 100%;
        }
    }
    .top-row{
        align-items: center;
        display: grid;
        grid-auto-flow: column;
        justify-content: flex-end;
        gap: 35px;
    }
    .keyword-form{
        border-bottom: 1px solid $c_font;
        position: relative;
        width: 200px;
        input{
            border: none;
            color: $c_font;
            font-size: 1rem /* 16/16 */;
            padding: 5px;
            width: 100%;
        }
        button{
            @include abs_pos($t:50%, $r:5px);
            background: none;
            border: none;
            color: $c_font;
            font-size: 1rem /* 16/16 */;
            padding: 0;
            transform: translateY(-50%);
            &:hover{
                color: $c_active;
            }
        }
    }
    .lang{
        display: grid;
        grid-auto-flow: column;
        gap: 10px;
        a{
            @include size(35px);
            border: 1px solid $c_bd;
            border-radius: 50%;
            display: block;
            position: relative;
            &.active{
                border-color: $c_active;
                background: $c_active;
                span{
                    color: $c_white;
                }
            }
            &:hover{
                border-color: $c_main;
                background: $c_main;
                span{
                    color: $c_white;
                }
            }
            span{
                @extend %center;
                color: $c_font;
                font-size: .875rem /* 14/16 */;
                font-weight: bold;
                line-height: 1;
                text-align: center;
                width: 100%;
            }
        }
    }
    nav{
        margin-top: 25px;
    }
    .main-menu{
        display: grid;
        grid-auto-flow: column;
        gap: 70px;
        justify-content: flex-end;
        >li{
            position: relative;
            &:hover{
                .main-link{
                    color: $c_active;
                }
            }
        }
        .main-link{
            color: $c_font;
            display: block;
            font-size: 1.0625rem /* 17/16 */;
            font-weight: bold;
            text-transform: uppercase;
        }
        .sub-menu{
            @include abs_pos($b:0, $l:50%);
            display: none;
            transform: translate(-50%, 100%);
            padding-top: 25px;
            width: 170px;
            li{
                background: rgba($c_main, .9);
                border-bottom: 1px solid $c_white;
            }
            a{
                color: $c_white;
                display: block;
                font-size: 1rem /* 16/16 */;
                line-height: 1.2;
                padding: 13px 10px;
                text-align: center;
                &:hover{
                    background: $c_linear;
                }
            }
        }
    }
}


.mobile-toggle {
    @include size(28px, 30px);
    background: none;
    display: none;
    position: relative;
    padding: 0;
    z-index: 4;

    &.open {
        span,
        span:before,
        span:after {
            background: $c_main;
        }

        span {
            background: none;

            &:before {
                transform: rotate(45deg) translate(6px, 5px);
            }

            &:after {
                transform: rotate(-45deg) translate(7px, -8px);
            }
        }
    }

    span {
        display: block;
        position: relative;

        &:before,
        &:after {
            @include abs_pos($t: -9px, $l: 0);
            content: "";
        }

        &:after {
            top: 9px;
        }
    }

    span,
    span:before,
    span:after {
        @include size(100%, 4px);
        backface-visibility: hidden;
        border-radius: 2px;
        background: $c_font;
        transition: all .4s ease;
    }
}